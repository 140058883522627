<script>
import ProductCard from './ProductCard.vue';

export default {
    components: {
        ProductCard
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    }
}
</script>


<template>
    <div class="product-grid">
        <template v-for="(product, index) in products" :key="index">
            <ProductCard :product="product"/>
        </template>
    </div>
</template>


<style lang="scss" scoped>
.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 1.6rem;
}


</style>
