<template>
    <div class="product" @click="addToCart">
        <div class="product-image" v-if="product.image">
            <img :src="product.image" alt="#"/>
        </div>
        <div class="product-image" v-else>
            <img src="images/placeholder.png" alt="Placeholder Image"/>
        </div>

        <div class="product-content">
            <h2>{{ product.name }}</h2>
            <span class="product-price">
                {{ product.price.toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'}) }}
            </span>
        </div>

    </div>
</template>


<script setup>
import {inject} from "vue";

const props = defineProps({
    product: {type: Object}
});

const orderItems = inject('orderItems');

const addToCart = () => {
    let tempOrderItems = orderItems.value;
    if (tempOrderItems[props.product.id]) {
        tempOrderItems[props.product.id].qty++;
    } else {
        tempOrderItems[props.product.id] = {
            product: props.product,
            qty: 1
        };
    }

    orderItems.value = tempOrderItems;
}

</script>

<style lang="scss" scoped>
.product {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 31.4rem;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 5rem;
    padding: 1.6rem 3.2rem;

    &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 16rem;
        width: 16rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        h2 {
            font-size: 1.7rem;
            line-height: 2.2rem;
            margin-bottom: 1.6rem;
            font-weight: normal;
        }
    }
}
</style>
